import React from "react"
import PropTypes from "prop-types"
import SEO from "components/seo"
import ProductCard from "../components/pricing/product-card"

const CheckIcon = ({ fill }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_150_376)">
      <circle cx="12" cy="12.5" r="12" fill={fill || "#BDC1C7"} />
      <path d="M6 12.5L10 16.5L18 8.5" stroke="white" strokeWidth="2" />
    </g>
    <defs>
      <clipPath id="clip0_150_376">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default function SelectPlan() {
  return (
    <>
      <SEO
        title="Sourcery | Select your plan"
        description="Choose your plan to start using Sourcery!"
        keywords={[`sourcery`, `pricing`]}
      />
      {/* start header */}
      <div className="bg-black-500 py-7 ">
        <div className="sm:pt-3 lg:pt-6 ">
          <div className="flex flex-col items-center mx-auto px-6">
            <h3 className="max-w-2xl text-3xl text-center text-white lg:text-6xl pb-4">
              Choose the Sourcery plan that fits your needs
            </h3>
            <p className="mb-4 text-white text-lg text-center sm:text-left">
              You can try out Sourcery Pro for closed-source projects for 14
              days for free.
            </p>
          </div>
        </div>

        <div className="relative z-10 px-6 mx-auto max-w-screen-2xl">
          <div className="flex flex-wrap gap-4 items-center justify-center">
            <ProductCard
              forText="For Open Source Projects"
              heading="Open Source"
              subHeading="Feedback on open source code anytime, anywhere."
              features={[]}
              link="/signup/?product=prod_opensource"
              variant="gray"
              cta="Get Started"
              plusButton="Free to use for Open Source"
              hasTrial={false}
            >
              <p className="font-bold text-3xl">Open Source</p>
            </ProductCard>

            {/* second card */}
            <ProductCard
              forText="For all projects"
              heading="Pro"
              subHeading="Improve any project with the full power of Sourcery."
              features={[
                "Real time feedback in IDE",
                "Bulk suggestions with the Sourcery CLI",
                "Unlimited custom rules",
              ]}
              link="/signup/?product=prod_pro"
              variant="orange"
              cta="Start Free Trial"
              plusButton="Use for any project "
              hasTrial={true}
            >
              <p className="text-black-300 text-3xl">
                <span className="font-bold text-black-500">$10</span> / month
              </p>
            </ProductCard>

            {/* third card */}
            <ProductCard
              forText="For teams"
              heading="Team"
              subHeading="Speed up development by ensuring best practices everywhere."
              features={[
                "Self hosted CI integration",
                "Pre commit hook",
                "Unlimited custom rules",
              ]}
              link="/signup/?product=prod_team"
              variant="purple"
              cta="Get Started"
              plusButton="All pro features plus..."
              hasTrial={false}
            >
              <p className="text-black-300 text-3xl">
                <span className="font-bold text-black-500">$30</span> / month /
                user
              </p>
            </ProductCard>
          </div>
        </div>
      </div>
      {/* end header */}
    </>
  )
}

CheckIcon.propTypes = {
  fill: PropTypes.string,
}
