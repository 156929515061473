import React from "react"
import PropTypes from "prop-types"
import { ArrowRightIcon, PlusIcon } from "@primer/octicons-react"

const variants = [
  {
    title: "orange",
    color: "orange-200",
    bg: "bg-orange-200",
  },
  {
    title: "gray",
    color: "black-200",
    bg: "bg-black-200",
  },
  {
    title: "purple",
    color: "purple-200",
    bg: "bg-purple-200",
  },
]

const ProductCard = (props) => {
  const currentVariant =
    variants.find(({ title }) => title === props.variant) || variants[0]

  return (
    <div className="flex flex-col basis-[26.33rem]">
      <div
        className={`relative bg-white flex flex-col rounded-t-sm items-start justify-start overflow-hidden pb-5 sm:pb-0 ${
          props.features ? "sm:min-h-[32rem]" : "sm:min-h-[12rem]"
        }`}
      >
        {props.ribbon && (
          <div
            className="absolute w-32 bg-red-500 text-black bg-orange-200 text-center font-bold"
            style={{ transform: "rotate(45deg)", top: "50px", right: "-70px" }}
          >
            <div className="py-2 px-8">{props.ribbon}</div>
          </div>
        )}

        <div className="w-full flex flex-col items-start justify-start min-h-[12.7rem]">
          <div className="w-full px-[2.5rem] pt-[2.5rem] pb-2">
            <p className="font-mono font-bold text-xl tracking-[-3%] mb-5">
              {props.heading}
            </p>
            {props.children}
            <p className="text-base text-black-300 mt-2 max-w-[20.66rem]">
              {props.subHeading}
            </p>
          </div>
          {props.plusButton ? (
            <div
              className={`font-bold ml-[2.5rem] mb-[1.15rem] mt-7 text-base px-5 py-2 rounded-full leading-7 ${currentVariant.bg}`}
            >
              {props.plusButton}
            </div>
          ) : null}
        </div>

        <div className="w-full">
          <ul className="line-border font-bold">
            {props.features
              ? props.features.map((feature, index) => (
                  <li
                    className={`flex items-center justify-start text-base text-black-500 pl-7 py-4 border-t border-black-100 ${
                      props.heading === "Free" && "first:border-t-0"
                    }`}
                    key={index}
                  >
                    {props.heading !== "Free" && (
                      <div
                        className={`text-${currentVariant.color} mr-4 -mt-3`}
                      >
                        <PlusIcon size={12} />
                      </div>
                    )}
                    <div>
                      <p className="text-gray-700">{feature}</p>
                    </div>
                  </li>
                ))
              : null}
          </ul>
        </div>
      </div>
      <a
        className={`${currentVariant.bg} flex justify-between items-center px-7 py-5 rounded-b-sm w-full hover:bg-opacity-90 `}
        href={props.link}
      >
        <p className="font-bold">{props.cta}</p>
        <ArrowRightIcon size={16} />
      </a>
    </div>
  )
}

ProductCard.propTypes = {
  children: PropTypes.node.isRequired,
  features: PropTypes.arrayOf(PropTypes.string),
  forText: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  plusButton: PropTypes.string,
  subHeading: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  cta: PropTypes.string.isRequired,
  ribbon: PropTypes.string,
}

export default ProductCard
